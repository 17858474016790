import Header from "../components/Header";
import Footer from "../components/Footer";
import KontaktForm from "../components/KontaktForm";
import { Link } from "react-router-dom";

const Kontakt = () => {
  return (
    <div>
      <Header />
      <div className="main">
        <h2 className="top_headline">KONTAKT</h2>
        <p className="imp_headline">Handelsagentur Marvin Saag</p>
        <div className="kontakt_absatz">
          <p>Turmstr. 60a</p>
          <p className="absatz">D-06110 Halle/Saale</p>
        </div>
        <div class="mapouter">
          <div class="gmap_canvas">
            <iframe
              title="adressMap"
              width="450"
              height="330"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=Turmstr.60a,%2006110%20Halle&t=&z=15&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
            <br />
            <a href="https://www.embedgooglemap.net">
              how to embed google map on website
            </a>
          </div>
        </div>
        <div className="absatz">
          <p className="kontakt_absatz">
            Telefon: <a href="tel:+4917657796946">+49 176 57 79 69 46</a>
          </p>
          <p>
            Email:{" "}
            <a href="mailto:info@handelsagentur-saag.de">
              info@handelsagentur-saag.de
            </a>
          </p>
        </div>
        <div className="absatz">
          <p>
            <Link to="/">www.handelsagentur-saag.de</Link>
          </p>
        </div>
        <KontaktForm />
      </div>
      <Footer />
    </div>
  );
};

export default Kontakt;
