import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="navbar_footer">
        <ul>
          <li>
            <Link to="/impressum">IMPRESSUM</Link>
          </li>
          <li>
            <Link to="/agb">AGB</Link>
          </li>
          <li>
            <Link to="/datenschutz">DATENSCHUTZ</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
