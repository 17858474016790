import Header from "../components/Header";
import Footer from "../components/Footer";

const Agb = () => {
  return (
    <div>
      <Header />
      <div className="main">
        <h2 className="top_headline">Allgemeine Geschäftsbedingungen,  Kundeninformationen, Widerrufsbelehrung</h2>

        <h2 className="imp_headline">I. Allgemeine Geschäftsbedingungen</h2>
        <div className="absatz">
          <p>
            § 1 Grundlegende Bestimmungen
          </p>
        </div>
        <div className="absatz">
          <p>
            (1) Die nachstehenden Geschäftsbedingungen gelten für alle Verträge, die Sie (Kunde) mit uns als Anbieter schließen. Soweit nicht anders vereinbart, wird der Einbeziehung gegebenenfalls von Ihnen verwendeter eigener Bedingungen widersprochen.
          </p>
        </div>
        <div className="absatz">
          <p>
            (2) Verbraucher im Sinne der nachstehenden Regelungen ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann. Unternehmer ist jede natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer selbständigen beruflichen oder gewerblichen Tätigkeit handelt.
          </p>
        </div>
        <div className="absatz">
          <p>
            (3) Die im Folgenden verwendeten Überschriften dienen der Übersichtlichkeit und einem besseren Verständnis. Sie sollen bei der Auslegung der einzelnen Bestimmungen dieser AGB außer Betracht bleiben.
          </p>
        </div>
        <div className="absatz">
          <p>
            § 2 Zustandekommen des Vertrages
          </p>
        </div>
        <div className="absatz">
          <p>
            (1) Gegenstand des Vertrages ist der Verkauf von Waren.
          </p>
        </div>
        <div className="absatz">
          <p>
            (2) Die Darstellung der Waren und Dienstleistungen in unserem Online-Shop (Ebay Kleinanzeigen) stellt kein rechtlich bindendes Angebot, sondern eine Aufforderung zur Bestellung (invitatio ad offerendum) dar.
          </p>
        </div>
        <div className="absatz">
          <p>
            (3) Ihre Anfragen zur Erstellung eines Angebotes sind für Sie unverbindlich. Wir unterbreiten Ihnen hierzu ein verbindliches Angebot in Textform (z.B. per E-Mail oder WhatsApp), welches Sie innerhalb von 7 Tagen annehmen können.
          </p>
        </div>
        <div className="absatz">
          <p>
            (4) Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen Informationen erfolgt per E-Mail oder WhatsApp zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die von Ihnen bei uns hinterlegte E-Mail-Adresse zutreffend ist, der Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch SPAM-Filter verhindert wird.
          </p>
        </div>
        <div className="absatz">
          <p>
            § 3 Zurückbehaltungsrecht, Eigentumsvorbehalt
          </p>
        </div>
        <div className="absatz">
          <p>
            (1) Ein Zurückbehaltungsrecht können Sie nur ausüben, soweit es sich um Forderungen aus demselben Vertragsverhältnis handelt.
          </p>
        </div>
        <div className="absatz">
          <p>
            (2) Die Ware bleibt bis zur vollständigen Zahlung des Kaufpreises unser Eigentum.
          </p>
        </div>
        <div className="absatz">
          <p>
            § 4 Gewährleistung, Nichtverfügbarkeit der Ware
          </p>
        </div>
        <div className="absatz">
          <p>
            (1) Es bestehen die gesetzlichen Mängelhaftungsrechte.
          </p>
        </div>
        <div className="absatz">
          <p>
          (2) Bei gebrauchten Sachen beträgt die Gewährleistungsfrist abweichend von der gesetzlichen Regelung ein Jahr ab Ablieferung der Sache. Die Fristverkürzung gilt nicht:
          </p>
        </div>
        <div className="absatz">
          <ul>
            <li>
              für uns zurechenbare schuldhaft verursachte Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und bei vorsätzlich oder grob fahrlässig verursachten sonstigen Schäden;
            </li>
            <li>
              soweit wir den Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Sache übernommen haben.
            </li>
          </ul>
        </div>
        <div className="absatz">
          <p>
            (3) Als Verbraucher werden Sie gebeten, die Sache bei Lieferung umgehend auf Vollständigkeit, offensichtliche Mängel und Transportschäden zu überprüfen und uns sowie dem Spediteur Beanstandungen schnellstmöglich mitzuteilen. Kommen Sie dem nicht nach, hat dies keine Auswirkung auf Ihre gesetzlichen Gewährleistungsansprüche.
          </p>
        </div>
        <div className="absatz">
          <p>
          (4) Wir übernehmen keine Verantwortung und / oder Haftung für die Kompatibilität der angebotenen Produkte mit anderen als in der Angebotsbeschreibung bezeichneten Fahrzeugmodellen. Ebenfalls übernehmen wir keine Verantwortung und / oder Haftung für die Zulassung der angebotenen Produkte für andere als in der Angebotsbeschreibung bezeichneten Fahrzeugmodelle.
          </p>
        </div>
        <div className="absatz">
          <p>
            (5) Soweit Sie Unternehmer sind, gilt abweichend von den vorstehenden Gewährleistungsregelungen:
          </p>
        </div>
        <div className="absatz">
          <p>
            a) Als Beschaffenheit der Sache gelten nur unsere eigenen Angaben und die Produktbeschreibung des Herstellers als vereinbart, nicht jedoch sonstige Werbung, öffentliche Anpreisungen und Äußerungen des Herstellers.
          </p>
        </div>
        <div className="absatz">
          <p>
            b) Erkennbare Mängel sind unverzüglich, spätestens innerhalb von 24 Stunden nach Auslieferung der Ware telefonisch, per WhatsApp oder E-Mail anzuzeigen. Zeigt sich später ein solcher Mangel, so mss die Anzeige unverzüglich nach der Entdeckung gemacht werden. Anderenfalls gilt die Ware als genehmigt. Der Anspruch auf Gewährleistung erlischt spätestens nach Ablauf eines Jahres ab Ablieferung der Ware. Ansprüche wegen Mangelfolgeschäden sind ausgeschlossen. 
          </p>
        </div>
        <div className="absatz">
          <p>c) Ziffer 5 b) gilt nicht:</p>
        </div>
        <div className="absatz">
          <ul>
            <li>
              für uns zurechenbare schuldhaft verursachte Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und bei vorsätzlich oder grob fahrlässig verursachten sonstigen Schäden;                      
            </li>
            <li>
              soweit wir den Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Sache übernommen haben; 
            </li>
            <li>
              bei gesetzlichen Rückgriffsansprüchen, die Sie im Zusammenhang mit Mängelrechten gegen uns haben.
            </li>
          </ul>
        </div>
        <div className="absatz">
          <p>
            d)  Bei Mängeln leisten wir nach unserer Wahl Gewähr durch Nachbesserung oder Nachlieferung. Schlägt die Mangelbeseitigung fehl, können Sie nach Ihrer Wahl Minderung verlangen oder vom Vertrag zurücktreten. Die Mängelbeseitigung gilt nach erfolglosem zweitem Versuch als fehlgeschlagen, wenn sich nicht insbesondere aus der Art der Sache oder des Mangels oder den sonstigen Umständen etwas anderes ergibt. Im Falle der Nachbesserung müssen wir nicht die erhöhten Kosten tragen, die durch die Verbringung der Ware an einen anderen Ort als den Erfüllungsort entstehen, sofern die Verbringung nicht dem bestimmungsgemäßen Gebrauch der Ware entspricht.  
          </p>
        </div>
        <div className="absatz">
          <p>
            § 5 Rechtswahl, Erfüllungsort, Gerichtsstand
          </p>
        </div>
        <div className="absatz">
          <p>
            (1) Es gilt deutsches Recht. Bei Verbrauchern gilt diese Rechtswahl nur, soweit hierdurch der durch zwingende Bestimmungen des Rechts des Staates des gewöhnlichen Aufenthaltes des Verbrauchers gewährte Schutz nicht entzogen wird (Günstigkeitsprinzip).
          </p>
        </div>
        <div className="absatz">
          <p>
            (2) Erfüllungsort für alle Leistungen aus den mit uns bestehenden Geschäftsbeziehungen sowie Gerichtsstand ist unser Sitz, soweit Sie nicht Verbraucher, sondern Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen sind. Dasselbe gilt, wenn Sie keinen allgemeinen Gerichtsstand in Deutschland oder der EU haben oder der Wohnsitz oder gewöhnliche Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt ist. Die Befugnis, auch das Gericht an einem anderen gesetzlichen Gerichtsstand anzurufen, bleibt hiervon unberührt.
          </p>
        </div>
        <div className="absatz">
          <p>
            (3) Die Bestimmungen des UN-Kaufrechts finden ausdrücklich keine Anwendung. 
          </p>
        </div>
        <h2 className="imp_headline">II. Kundeninformationen</h2>
        <div className="absatz">
          <p>
          1. Identität des Verkäufers
          </p>
        </div>
        <div className="absatz">
          <p>Handelsagentur Marvin Saag</p>
          <p>Turmstr. 60a</p>
          <p>06110 Halle</p>
          <p>Deutschland</p>
          <p>Telefon: 0176-57796946</p>
          <p>E-Mail: info@handelsagentur-saag.de</p>
        </div>
        <div className="absatz">
          <p>2. Informationen zum Zustandekommen des Vertrages</p>
        </div>
        <div className="absatz">
          <p>Die technischen Schritte zum Vertragsschluss, der Vertragsschluss selbst und die Korrekturmöglichkeiten erfolgen nach Maßgabe der Regelungen "Zustandekommen des Vertrages" unserer Allgemeinen Geschäftsbedingungen (Teil I.).
          </p>
        </div>
        <div className="absatz">
          <p>
            3. Vertragssprache, Vertragstextspeicherung
          </p>
        </div>
        <div className="absatz">
          <p>3.1. Vertragssprache ist deutsch.</p>
        </div>
        <div className="absatz">
          <p>
            4. Wesentliche Merkmale der Ware oder Dienstleistung
          </p>
        </div>
        <div className="absatz">
          <p>
          4.1. Die wesentlichen Merkmale der Ware und/oder Dienstleistung finden sich im jeweiligen Angebot.
          </p>
        </div>
        <div className="absatz">
          <p>
          5. Preise, Versandkosten und Zahlungsmodalitäten
          </p>
        </div>
        <div className="absatz">
          <p>5.1. Die in den jeweiligen Angeboten angeführten Preise stellen nicht den Gesamtpreise dar. Die anfallenden Versandkosten sind zusätzlich zu tragen. Der Gesamtpreis bildet alle Preisbestandteile einschließlich aller anfallenden Steuern und Gebühren ab.
          </p>
        </div>
        <div className="absatz">
          <p>
          5.2. Erfolgt die Lieferung in Länder außerhalb der Europäischen Union können von uns nicht zu vertretende weitere Kosten anfallen, wie z.B. Zölle, Steuern oder Geldübermittlungsgebühren (Überweisungs- oder Wechselkursgebühren der Kreditinstitute), die von Ihnen zu tragen sind. Entstandene Kosten der Geldübermittlung sind von Ihnen auch in den Fällen zu tragen, in denen die Lieferung in ein EU-Mitgliedsstaat erfolgt, die Zahlung aber außerhalb der Europäischen Union veranlasst wurde.
          </p>
        </div>
        <div className="absatz">
          <p>
          5.3. Soweit bei den einzelnen Zahlungsarten nicht anders angegeben, sind die Zahlungsansprüche aus dem geschlossenen Vertrag sofort zur Zahlung fällig.
          </p>
        </div>
        <div className="absatz">
          <p>
          5.4. Die Zahlung kann per Überweisung (Vorkasse), Paypal oder bar erfolgen. Bei einer Paypal Bezahlung können zusätzliche Kosten entstehen, die durch den Käufer zu decken sind.
          </p>
        </div>
        <div className="absatz">
          <p>
          6. Lieferbedingungen
          </p>
        </div>
        <div className="absatz">
          <p>6.1. Die Lieferbedingungen, der Liefertermin sowie gegebenenfalls bestehende Lieferbeschränkungen finden sich unter einer entsprechend bezeichneten Schaltfläche auf unserer Internetpräsenz oder im jeweiligen Angebot. 
          </p>
        </div>
        <div className="absatz">
          <p>
            6.2. Soweit Sie Verbraucher sind, ist gesetzlich geregelt, dass die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung der verkauften Sache während der Versendung erst mit der Übergabe der Ware an Sie übergeht, unabhängig davon, ob die Versendung versichert oder unversichert erfolgt. Dies gilt nicht, wenn Sie eigenständig ein nicht vom Unternehmer benanntes Transportunternehmen oder eine sonst zur Ausführung der Versendung bestimmte Person beauftragt haben.
          </p>
        </div>
        <div className="absatz">
          <p>
          6.3. Sind Sie Unternehmer, erfolgt die Lieferung und Versendung auf Ihre Gefahr.
          </p>
        </div>
        <div className="absatz">
          <p>
          6.4. Soweit eine Lieferung an Sie nicht möglich ist, weil die gelieferte Ware nicht durch die Eingangstür, Haustür oder den Treppenaufgang passt oder weil Sie nicht unter der von Ihnen angegebenen Lieferadresse angetroffen werden, obwohl der Lieferzeitpunkt dem mit angemessener Frist angekündigt wurde, tragen Sie die mit der erfolglosen Anlieferung sowie der Aufbewahrung und dem Erhalt der Ware zusammenhängende Kosten.
          </p>
        </div>
        <div className="absatz">
          <p>7. Gesetzliches Mängelhaftungsrecht</p>
        </div>
        <div className="absatz">
          <p>Die Mängelhaftung richtet sich nach der Regelung "Gewährleistung" in unseren Allgemeinen Geschäftsbedingungen (Teil I).
          </p>
        </div>
        <h2 className="imp_headline">III. Widerrufsbelehrung</h2>
        <div className="absatz">
          <p>Widerrufsrecht</p>
        </div>
        <div className="absatz">
          <p>
            Sie haben das Recht, binnen 30 Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Dies gilt nicht, für Bestellungen auf Kundenwunsch/Einzelanfertigungen.
          </p>
        </div>
        <div className="absatz">
          <p>
            Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Handelsagentur Marvin Saag, Turmstr. 60a, 06110 Halle, Telefon.: 0176-57796946, E-Mail-Adresse: info@handelsagentur-saag.de) mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, WhatsApp oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
          </p>
        </div>
        <div className="absatz">
          <p>
            Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
          </p>
        </div>
        <h2 className="imp_headline">Folgen des Widerrufs</h2>
        <div className="absatz">
          <p>
          Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben zurückzuzahlen. 
          </p>
        </div>
        <div className="absatz">
          <p>Wir behalten uns vor die Rückzahlung erst vorzunehmen, bis wir die Waren wieder zurückerhalten und geprüft haben. Die Rückzahlung erfolgt spätestens 14 Tagen nach Rückerhalt der Ware.
          </p>
        </div>
        <div className="absatz">
          <p>Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart.
          </p>
        </div>
        <div className="absatz">
          <p>Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen 14 Tagen und in Originalverpackung sowie -Zustand ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden.
          </p>
        </div>
        <div className="absatz">
          <p>
          Sie tragen die unmittelbaren Kosten der Rücksendung in voller Höhe.
          </p>
        </div>
        <div className="absatz">
          <p>Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.
          </p>
        </div>
        <div className="absatz">
          <p>Ausschluss- bzw. Erlöschensgründe
          </p>
        </div>
        <div className="absatz">
          <p>Das Widerrufsrecht besteht nicht bei Verträgen zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind, sowie Ware auf Bestellung. 
          </p>
        </div>
        <h2 className="imp_headline">Muster-Widerrufsformular</h2>
        <div className="absatz">
          <p>Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.
          </p>
        </div>
        <div className="absatz">
          <ul>
            <li>
              <p>Handelsagentur Marvin Saag</p>
              <p>Turmstr. 60a</p>
              <p>06110 Halle</p>
              <p>Telefon: 0176-57796946</p>
              <p>E-Mail: info@handelsagentur-saag.de</p>
            </li>
          </ul>
        </div>
        <div className="absatz">
          <ul>
            <li>Hiermit widerrufe(n) ich/ wir (*) den von mir/ uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/ die Erbringung der folgenden Dienstleistung (*)</li>
            <li>Bestellt am (*)/ erhalten am (*)</li>
            <li>Name des/ der Verbraucher(s)</li>
            <li>Anschrift des/ der Verbraucher(s)</li>
            <li>Unterschrift des/ der Verbraucher(s) (nur bei Mitteilung auf Papier)</li>
            <li>Datum</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Agb;
