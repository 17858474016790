import logo from "../img/logo-100.png";
import whatsapp from "../img/whatsapp-48.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <div className="whatsapp">
        <div>
          BEI FRAGEN ZU UNSEREN PRODUKTEN KONTAKTIEREN SIE UNS TELEFONISCH UNTER{" "}
          <a href="tel:+4917657796946">+49176 57 79 69 46</a> ODER DIREKT PER{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send?phone=4917657796946"
          >
            <span>WHATSAPP</span>{" "}
            <img className="whatsapp_logo" src={whatsapp} alt="Whatsapp Logo" />
          </a>
        </div>
      </div>
      <div className="header">
        <div id="headline">
          <img src={logo} alt="Handelsagentur Saag Logo" />
          <div>
            <h1>Handelsagentur Saag</h1>
            <p>Marvin Saag</p>
          </div>
        </div>
        <ul className="navbar">
          <li>
            <Link to="/">HOME</Link>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.ebay-kleinanzeigen.de/s-bestandsliste.html?userId=119290596"
            >
              SHOP
            </a>
          </li>
          <li>
            <Link to="/kontakt">KONTAKT</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
