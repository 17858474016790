import { Link } from "react-router-dom";

const Main = () => {
  return (
    <div className="main">
      <h2 className="top_headline">HERZLICH WILLKOMMEN AUF UNSERER HOMEPAGE</h2>
      <h2 className="home_headline">WAS MACHEN WIR</h2>
      <p className="home_content">
        Wir haben uns auf den Handel mit hochwertigen Alufelgen spezialisiert. In unserem Sortiment befinden sich neben modernen Felgen auch etliche Modelle für Ihren Young-/ Oldtimer.
      </p>
      <p className="home_content">
        Den Fokus haben wir dabei auf MERCEDES BENZ, AMG, BRABUS und PORSCHE FELGEN gelegt.
      </p>
      <p className="home_content">
      In unserem <a
          target="_blank"
          rel="noreferrer"
          href="https://www.ebay-kleinanzeigen.de/s-bestandsliste.html?userId=119290596"
        >
          <span>SHOP</span>
        </a>{" "} befinden sich stehts eine Vielzahl neu aufgearbeiteter Felgensätze welche sofort lieferbar sind. 
      </p>
      <p className="home_content">
      Gerne fertigen wir Ihnen unsere Felgen aber auch nach Ihrem persönlichen Kundenwusch an oder besorgen für Sie im Auftrag Ihr gewünschtes Rad. 
      </p>
      <p className="home_content">
        Nutzen sie hierzu das <Link to="/kontakt">Kontaktformular</Link>,{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://api.whatsapp.com/send?phone=4917657796946"
        >
          <span>Whatsapp</span>
        </a>{" "}
        oder <a href="tel:+4917657796946">rufen</a> Sie uns an.
      </p>
      <h2 className="home_headline">WARUM SOLLTEN SIE BEI UNS KAUFEN</h2>
      <p className="home_content">
        Viele unserer angebotenen Felgensätze sind seit einigen Jahren nicht mehr beim Hersteller lieferbar. Wir haben jedoch noch Sätze in verschiedenen Größen auf Lager.
      </p>
      <p className="home_content">
        Unsere Felgen werden alle professionell neu aufgearbeitet und auf einen technisch einwandfreien Zustand überprüft. Wir verkaufen ausschließlich Originale und geprüfte Alufelgen. 
      </p>
      <p className="home_content">
        Auf Wunsch können Sie auch bei der Veredelung Ihrer Räder mitbestimmen, damit wir ein individuelles Design für Sie erschaffen. 
      </p>
      <p className="home_content">
        Unsere Felgen werden alle professionell neu aufgearbeitet und auf einen
        technisch einwandfreien Zustand überprüft.
      </p>
      <p className="home_content">
        Sie können sich gerne vor Ort persönlich von der Qualität überzeugen oder werden umfangreich und ausführlich vorab telefonisch beraten.
      </p>
    </div>
  );
};

export default Main;
