import { useState } from "react";

const FORM_ENDPOINT =
  "https://public.herotofu.com/v1/cfbd3560-0782-11ed-be50-e78da9ee852d";

const KontaktForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <>
        <h2 className="imp_headline">Vielen Dank für Ihre Nachricht!</h2>
        <p className="home_content">Wir melden uns bei Ihnen.</p>
      </>
    );
  }

  return (
    <form
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method="POST"
      target="_blank"
      className="contactform"
    >
      <h2 className="imp_headline">Kontaktformular</h2>
      <div className="inpt_name">
        <input type="text" placeholder="Vorname" name="vorname" required />
      </div>
      <div className="inpt_name">
        <input type="text" placeholder="Nachname" name="nachname" required />
      </div>
      <div className="inpt_email">
        <input type="email" placeholder="Email" name="email" required />
      </div>
      <div className="inpt_phone">
        <input type="tel" placeholder="Tel. Nr." name="tel" required />
      </div>
      <div className="inpt_msg">
        <textarea placeholder="Ihre Nachricht" name="message" required rows='5' cols='20' />
      </div>
      <div className="submit_btn">
        <button type="submit"> Nachricht abschicken </button>
      </div>
    </form>
  );
};

export default KontaktForm;
