import Header from "../components/Header";
import Footer from "../components/Footer";

const Impressum = () => {
  return (
    <div>
      <div>
        <Header />
        <div className="main">
          <h2 className="top_headline">Impressum</h2>
          <div className="impressum">
            <div>
              <h2 className="imp_headline">Handelsagentur Marvin Saag</h2>
              <p>Inh. Marvin Saag</p>
              <p className="imp_p">Turmstr. 60a</p>
              <p>06110 Halle</p>
              <p>
                Telefon: <a href="tel:+4917657796946">+49 176 57 79 69 46</a>
              </p>
              <p>
                Email:{" "}
                <a href="mailto:info@handelsagentur-saag.de">
                  info@handelsagentur-saag.de
                </a>
              </p>
              <p className="imp_p">USt-IdNr: DE 301175141</p>
              <p className="imp_p">Geschäftskonto: Commerzbank Halle</p>
              <p>IBAN DE73 8004 0000 0321 2925 00</p>
              <p className="imp_p_last">Paypal: handelsagentur-saag@mail.de</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Impressum;
