import Header from "../components/Header";
import Footer from "../components/Footer";

const Datenschutz = () => {
  return (
    <div>
      <Header />
      <div className="main">
        <h2 className="top_headline">Haftungsbeschränkung für Inhalte</h2>
        <div className="absatz">
          <p>
            Die Inhalte unserer Seite wurden mit größter Sorgfalt erstellt. Die
            Nutzung der Website erfolgt jedoch auf eigene Gefahr des Nutzers.
            Für die Richtigkeit, Vollständigkeit und Aktualität können wir keine
            Gewähr übernehmen. Mit der Bereitstellung ist auch nicht die
            ständige oder fehlerfreie Verfügbarkeit unserer Website oder des
            darauf abrufbaren Services gewährleistet. Von uns kann leider auch
            nicht gewährleistet werden, dass keine Viren oder andere schädliche
            Bestandteile vorhanden sind.
          </p>
        </div>
        <div className="absatz">
          <p>
            In namentlich gekennzeichneten Beiträgen oder sonst wie von Dritten
            an uns übermittelten Inhalten werden ausschließlich die Meinungen
            und Informationen des jeweiligen Autors wiedergegeben, also nicht
            unsere Meinung als Anbieter. Wir sind nicht verpflichtet, derartig
            an uns übermittelte oder gespeicherte Informationen zu überwachen
            oder nach Umständen zu forschen, die auf eine rechtswidrige
            Tätigkeit hinweisen. Eine derartige Haftung käme erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung in Betracht.
            Bei Bekanntwerden etwaig derartiger Rechtsverletzungen werden wir
            diese Inhalte umgehend entfernen.
          </p>
        </div>
        <div className="absatz">
          <p>
            Mit der Nutzung unserer Website entsteht kein Vertragsverhältnis
            zwischen uns als Anbieter und dem Nutzer. Die auf unserer Website
            enthaltenen Angebote sind unverbindlich und freibleibend. Unsere
            Website oder Teile hier von können ohne gesonderte Ankündigung an
            den Nutzer verändert, gelöscht oder die Veröffentlichung zeitweise
            oder endgültig eingestellt werden.
          </p>
        </div>
        <div className="absatz">
          <p>
            Schadenersatzansprüche aus der Nutzung der Website aus gleich
            welchem Rechtsgrund sind ausgeschlossen. Etwas anderes gilt nur
            dann, wenn nach gesetzlichen Vorgaben zwingend gehaftet wird, z.B.
            nach dem Produkthaftungsgesetz, bei Vorsatz oder grober
            Fahrlässigkeit, der Verletzung des Lebens oder der körperlichen
            Unversehrtheit, bei vertragswesentlichen Pflichtverletzungen oder
            der Verletzung gesetzlich vorgegebener Kardinalpflichten. In diesen
            Fällen wird der Schadenersatz jedoch auf den vorhersehbaren
            unmittelbaren Schaden begrenzt, es sei denn, es liegt Vorsatz oder
            grobe Fahrlässigkeit oder die Verletzung des Lebens, des Körpers
            oder der Gesundheit vor.
          </p>
        </div>
        <div className="absatz">
          <p>Haftung für externe Links und Verweise</p>
        </div>
        <div className="absatz">
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben und die außerhalb unseres
            Verantwortungsbereiches als Anbieter dieser Websites liegen. Für
            illegale, fehlerhafte, unvollständige Inhalte und Schäden, die durch
            die Nutzung der verlinkten Seiten und dortige Informationen
            entstehen, haftet ausschließlich der Anbieter der Seite, auf welche
            verwiesen wurde. Wir erklären ausdrücklich, dass uns bei Linksetzung
            keine rechtswidrigen Inhalte dieser zu verlinkenden Seiten bekannt
            waren.
          </p>
        </div>
        <div className="absatz">
          <p>
            Eine Haftung von uns für verlinkte Websites würde ausschließlich
            dann durchgreifen, wenn wir als Anbieter von rechtswidrigen Inhalten
            auf fremden, direkt oder indirekt verlinkten Seiten Kenntnis haben
            und es uns technisch möglich und zumutbar wäre, die Nutzung dieser
            Verweisungen für den Fall rechtswidriger dortiger Inhalte zu
            verhindern. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ohne konkrete Anhaltspunkte für eine Rechtsverletzung ist
            nicht möglich und nicht zumutbar. Wir distanzieren uns aber hiermit
            bereits jetzt ausdrücklich von allen verlinkten / verknüpften
            Seiten, die nach der Verlinkung verändert wurden oder rechtswidrige
            und/oder fehlerhafte Inhalte von verlinkten Seiten fremder Anbieter,
            deren etwaig rechtswidrige Inhalte nicht erkennbar sind und waren.
            Diese Distanzierung gilt auch für alle etwaig möglichen und
            erfolgten Fremdeinträge in von uns angebotenen Rubriken oder
            sonstigen Datenbanken, auf deren Inhalt externe Schreibzugriffe
            möglich sind.
          </p>
        </div>
        <div className="absatz">
          <p>Urheber- und Leistungsschutzrechte</p>
        </div>
        <div className="absatz">
          <p>
            Der Inhalt dieser Website steht im Eigentum der Thomas Olmes
            Handelsagentur, wenn keine Kennzeichnung eines anderen Berechtigten
            vorliegt. Soweit die Inhalte auf dieser Seite nicht vom Anbieter und
            Betreiber erstellt wurden, sind die Urheberrechte Dritter beachtet.
            Die Inhalte unterstehen insgesamt dem Schutz des deutschen sowie
            ausländischen Urheber- und Markenrechtes. Eine Vervielfältigung oder
            Verwendung von entweder kompletter Seiten oder einzelner Inhalte der
            Website wie Grafiken, Tondokumenten, Videosequenzen oder Texte in
            anderen elektronischen oder gedruckten Publikationen oder zu
            sonstigen kommerziellen Zwecken ist ohne ausdrückliche Zustimmung
            des Berechtigten nicht gestattet.
          </p>
        </div>
        <div className="absatz">
          <p>Datenschutz</p>
        </div>
        <div className="absatz">
          <p>
            Durch den Besuch des Nutzers auf unserer Website können
            Informationen über den Zugriff z.B. in Form der konkret aufgerufenen
            Seite, Uhrzeit und Datum gespeichert werden. Diese Daten sind
            anonymisiert. Es handelt sich nicht um personenbezogene Daten. Eine
            Weitergabe an Dritte findet weder aus kommerziellen, noch aus nicht
            kommerziellen Zwecken statt.
          </p>
        </div>
        <div className="absatz">
          <p>
            Sofern innerhalb unseres Internetangebotes die Möglichkeit zur
            Eingabe persönlicher oder geschäftlicher Daten (E-Mail-Adressen,
            Namen, Anschriften usw.) besteht, erfolgt die Mitteilung dieser
            Daten des Nutzers an uns auf ausdrücklich freiwilliger Basis.
          </p>
        </div>
        <div className="absatz">
          <p>
            Personenbezogene Daten werden nur mit Ihrer Einwilligung zur
            Beantwortung von Anfragen, Abwicklung von Verträgen und der
            technischen Administration erhoben. Es erfolgt eine Speicherung,
            ohne dass diese für Dritte zugänglich wird. Eine Weitergaben an
            Dritte kann nur zum Zweck der Vertragsabwicklung oder bei Ihrer
            Einwilligung erfolgen, die jederzeit widerrufen werden kann.
            Rechtlich kann von Ihnen Auskunft über die so betroffenen,
            personenbezogenen gespeicherten Daten, den Anlass und Grund der
            Speicherung und die Herkunft der Daten verlangt werden. Im
            gesetzlichen Rahmen besteht auch ein Recht auf Berichtigung,
            Sperrung und Löschung von personenbezogenen Daten. Die Anfrage oder
            eine Berichtigung, Sperrung oder Löschung der Sie betreffenden
            personenbezogenen Daten ist über die auf unserer Website genannten
            Kontaktdaten möglich.
          </p>
        </div>
        <div className="absatz">
          <p>
            Wir weisen ausdrücklich darauf hin, dass im Falle einer
            Datenübertragung im Internet insbesondere per Mail Sicherheitslücken
            bestehen können und durch uns als Anbieter ein lückenloser Schutz
            der übermittelten Daten vor dem Zugriff durch Dritte nicht
            gewährleistet werden kann.
          </p>
        </div>
        <div className="absatz">
          <p>
            Der Anbieter dieser Website und alle auf dieser Website genannten
            Personen widersprechen hiermit jeder kommerziellen oder nicht
            kommerziellen Verwendung und Weitergabe ihrer Daten.
          </p>
        </div>
        <div className="absatz">
          <p>
            Die Verwendung der Kontaktdaten des Impressums durch Dritte zur
            gewerblichen Werbung oder Übersendung nicht angeforderter
            Informationen wird ausdrücklich untersagt, es sei denn, durch uns
            als Anbieter wurde zuvor die konkrete schriftliche Einwilligung
            hierzu erteilt oder es besteht bereits eine Geschäftsbeziehung. Wir
            behalten uns ausdrücklich rechtliche Schritte bei Verstößen
            hiergegen vor, so insbesondere gegen die Versender sog. Spam-Mails.
          </p>
        </div>
        <div className="absatz">
          <p>Änderung unserer Nutzungsbedingungen</p>
        </div>
        <div className="absatz">
          <p>
            Wir behalten uns vor, unsere Nutzungsbedingungen jederzeit zu
            ändern. Eine Benachrichtigung unserer Besucher und der Nutzer
            hierüber erfolgt durch die Einstellung der aktualisierten
            Nutzungsbedingungen in dieser Website.
          </p>
        </div>
        <div className="absatz">
          <p>Rechtswirksamkeit des Haftungsausschlusses</p>
        </div>
        <div className="absatz">
          <p>
            Unsere vorstehenden Haftungsausschlüsse und Hinweise sind
            Bestandteil unseres Internetangebotes, von dem aus auf diese Seite
            verwiesen wird. Sollte eine Bestimmung dieser Haftungsregelungen /
            Disclaimers ganz oder teilweise unwirksam sein oder werden, so wird
            dadurch die Gültigkeit der übrigen Bestimmungen dieser
            Haftungsregelungen / Disclaimers nicht berührt.
          </p>
        </div>
        <div className="absatz">
          <p>Schlussbestimmungen</p>
        </div>
        <div className="absatz">
          <p>Gerichtsstand ist, soweit rechtlich zulässig, Halle.</p>
        </div>
        <div className="absatz">
          <p>Anwendbares Recht ist das der Bundesrepublik Deutschland.</p>
        </div>
        <h2 className="imp_headline">Datenschutzerklärung</h2>
        <div className="absatz">
          <p>
            Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere
            der EU-Datenschutzgrundverordnung (DSGVO), ist:
          </p>
          <p>Marvin Saag</p>
        </div>
        <div className="absatz">
          <p>Ihre Betroffenenrechte</p>
          <p>
            Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
            können Sie jederzeit folgende Rechte ausüben:
          </p>
        </div>
        <div className="absatz">
          <ul>
            <li>
              Auskunft über Ihre bei uns gespeicherten Daten und deren
              Verarbeitung (Art. 15 DSGVO),
            </li>
            <li>
              Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
            </li>
            <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
            <li>
              Einschränkung der Datenverarbeitung, sofern wir Ihre Daten
              aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18
              DSGVO),
            </li>
            <li>
              Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21
              DSGVO) und
            </li>
            <li>
              Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
              eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben
              (Art. 20 DSGVO).
            </li>
          </ul>
        </div>
        <div className="absatz">
          <p>
            Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
            jederzeit mit Wirkung für die Zukunft widerrufen.
          </p>
        </div>
        <div className="absatz">
          <p>
            Sie können sich jederzeit mit einer Beschwerde an eine
            Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde
            des Bundeslands Ihres Wohnsitzes oder an die für uns als
            verantwortliche Stelle zuständige Behörde.
          </p>
        </div>
        <div className="absatz">
          <p>
            Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich)
            mit Anschrift finden Sie unter:{" "}
            <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
              https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
            </a>{" "}
          </p>
        </div>
        <h2 className="imp_headline">
          Erfassung allgemeiner Informationen beim Besuch unserer Website
        </h2>
        <div className="absatz">
          <p>Art und Zweck der Verarbeitung:</p>
          <p>
            Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht
            registrieren oder anderweitig Informationen übermitteln, werden
            automatisch Informationen allgemeiner Natur erfasst. Diese
            Informationen (Server-Logfiles) beinhalten etwa die Art des
            Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres
            Internet-Service-Providers, Ihre IP-Adresse und ähnliches. Hierbei
            handelt es sich ausschließlich um Informationen, welche keine
            Rückschlüsse auf Ihre Person zulassen.
          </p>
        </div>
        <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
        <div className="absatz">
          <ul>
            <li>
              Sicherstellung eines problemlosen Verbindungsaufbaus der Website,
            </li>
            <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
            <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
            <li>zu weiteren administrativen Zwecken.</li>
          </ul>
        </div>
        <div className="absatz">
          <p>
            Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu
            ziehen. Informationen dieser Art werden von uns ggfs. statistisch
            ausgewertet, um unseren Internetauftritt und die dahinterstehende
            Technik zu optimieren.
          </p>
        </div>
        <div className="absatz">
          <p>Rechtsgrundlage:</p>
          <p>
            Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
            unseres berechtigten Interesses an der Verbesserung der Stabilität
            und Funktionalität unserer Website.
          </p>
        </div>
        <div className="absatz">
          <p>Empfänger:</p>
          <p>
            Empfänger der Daten sind ggf. technische Dienstleister, die für den
            Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter
            tätig werden.
          </p>
        </div>
        <div className="absatz">
          <p>Speicherdauer:</p>
          <p>
            Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung
            nicht mehr erforderlich sind. Dies ist für die Daten, die der
            Bereitstellung der Webseite dienen, grundsätzlich der Fall, wenn die
            jeweilige Sitzung beendet ist.
          </p>
        </div>
        <div className="absatz">
          <p>Bereitstellung vorgeschrieben oder erforderlich:</p>
          <p>
            Die Bereitstellung der vorgenannten personenbezogenen Daten ist
            weder gesetzlich noch vertraglich vorgeschrieben. Ohne die
            IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer
            Website nicht gewährleistet. Zudem können einzelne Dienste und
            Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund
            ist ein Widerspruch ausgeschlossen.
          </p>
        </div>
        <h2 className="imp_headline">Cookies</h2>
        <div className="absatz">
          <p>Art und Zweck der Verarbeitung:</p>
          <p>
            Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“.
            Cookies sind kleine Textdateien, die von einem Websiteserver auf
            Ihre Festplatte übertragen werden. Hierdurch erhalten wir
            automatisch bestimmte Daten wie z. B. IP-Adresse, verwendeter
            Browser, Betriebssystem und Ihre Verbindung zum Internet.
          </p>
        </div>
        <div className="absatz">
          <p>
            Cookies können nicht verwendet werden, um Programme zu starten oder
            Viren auf einen Computer zu übertragen. Anhand der in Cookies
            enthaltenen Informationen können wir Ihnen die Navigation
            erleichtern und die korrekte Anzeige unserer Webseiten ermöglichen.
          </p>
        </div>
        <div className="absatz">
          <p>
            In keinem Fall werden die von uns erfassten Daten an Dritte
            weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit
            personenbezogenen Daten hergestellt.
          </p>
        </div>
        <div className="absatz">
          <p>
            Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies
            betrachten. Internet-Browser sind regelmäßig so eingestellt, dass
            sie Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung
            von Cookies jederzeit über die Einstellungen Ihres Browsers
            deaktivieren. Bitte verwenden Sie die Hilfefunktionen Ihres
            Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen ändern
            können. Bitte beachten Sie, dass einzelne Funktionen unserer Website
            möglicherweise nicht funktionieren, wenn Sie die Verwendung von
            Cookies deaktiviert haben.
          </p>
        </div>
        <div className="absatz">
          <p>Speicherdauer und eingesetzte Cookies:</p>
          <p>
            Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die
            Verwendung von Cookies erlauben, können folgende Cookies auf unseren
            Webseiten zum Einsatz kommen:
          </p>
        </div>
        <div className="absatz">
          <p>
            Sitzungscookies (diese werden nach Beendigung der Sitzung gelöscht)
          </p>
        </div>
        <div className="absatz">
          <p>
            Soweit diese Cookies (auch) personenbezogene Daten betreffen können,
            informieren wir Sie darüber in den folgenden Abschnitten.
          </p>
        </div>
        <div className="absatz">
          <p>
            Sie können über Ihre Browsereinstellungen einzelne Cookies oder den
            gesamten Cookie-Bestand löschen. Darüber hinaus erhalten Sie
            Informationen und Anleitungen, wie diese Cookies gelöscht oder deren
            Speicherung vorab blockiert werden können. Je nach Anbieter Ihres
            Browsers finden Sie die notwendigen Informationen unter den
            nachfolgenden Links:
          </p>
        </div>
        <div className="absatz">
          <ul>
            <li>
              Mozilla Firefox:{" "}
              <a href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen">
                https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen
              </a>
            </li>
            <li>
              {" "}
              Internet Explorer:{" "}
              <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">
                https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
              </a>
            </li>
            <li>
              Google Chrome:{" "}
              <a href="https://support.google.com/accounts/answer/61416?hl=de">
                https://support.google.com/accounts/answer/61416?hl=de
              </a>
            </li>
            <li>
              Opera:{" "}
              <a href="http://www.opera.com/de/help">
                http://www.opera.com/de/help
              </a>
            </li>
            <li>
              Safari:{" "}
              <a href="https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE">
                https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE
              </a>
            </li>
          </ul>
        </div>
        <h2 className="imp_headline">Kontaktformular</h2>
        <div className="absatz">
          <p>Art und Zweck der Verarbeitung:</p>
          <p>
            Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen
            Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer
            validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient
            der Zuordnung der Anfrage und der anschließenden Beantwortung
            derselben. Die Angabe weiterer Daten ist optional.
          </p>
        </div>
        <div className="absatz">
          <p>Rechtsgrundlage:</p>
          <p>
            Die Verarbeitung der in das Kontaktformular eingegebenen Daten
            erfolgt auf der Grundlage eines berechtigten Interesses (Art 6 Abs.
            1 lit. f DSGVO).
          </p>
        </div>
        <div className="absatz">
          <p>
            Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine
            unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben
            werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
            Anschlussfragen gespeichert.
          </p>
        </div>
        <div className="absatz">
          <p>
            Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen,
            erfolgt die Verarbeitung der in das Kontaktformular eingegebenen
            Daten zur Durchführung vorvertraglicher Maßnahmen (Art 6 Abs. 1 lit.
            b DSGVO).
          </p>
        </div>
        <div className="absatz">
          <p>Empfänger:</p>
          <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
        </div>
        <div className="absatz">
          <p>Speicherdauer:</p>
          <p>
            Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage
            gelöscht.
          </p>
        </div>
        <div className="absatz">
          <p>
            Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den
            gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten
            nach Ablauf dieser Fristen.
          </p>
        </div>
        <div className="absatz">
          <p>Bereitstellung vorgeschrieben oder erforderlich:</p>
          <p>
            Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig.
            Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren
            Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.
          </p>
        </div>
        <h2 className="imp_headline">
          Verwendung von Scriptbibliotheken (Google Webfonts)
        </h2>
        <div className="absatz">
          <p>Art und Zweck der Verarbeitung:</p>
          <p>
            Um unsere Inhalte browserübergreifend korrekt und grafisch
            ansprechend darzustellen, verwenden wir auf dieser Website „Google
            Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View,
            CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.
          </p>
        </div>
        <div className="absatz">
          <p>
            Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie
            hier:{" "}
            <a href="https://www.google.com/policies/privacy/">
              https://www.google.com/policies/privacy/
            </a>
          </p>
        </div>
        <div className="absatz">
          <p>Rechtsgrundlage:</p>
          <p>
            Rechtsgrundlage für die Einbindung von Google Webfonts und dem damit
            verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6
            Abs. 1 lit. a DSGVO).
          </p>
        </div>
        <div className="absatz">
          <p>Empfänger:</p>
          <p>
            Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst
            automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei
            ist es theoretisch möglich – aktuell allerdings auch unklar ob und
            ggf. zu welchen Zwecken – dass der Betreiber in diesem Fall Google
            Daten erhebt.
          </p>
        </div>
        <div className="absatz">
          <p>Speicherdauer:</p>
          <p>
            Wir erheben keine personenbezogenen Daten, durch die Einbindung von
            Google Webfonts.
          </p>
        </div>
        <div className="absatz">
          <p>
            Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
            <a href="https://developers.google.com/fonts/faq">
              https://developers.google.com/fonts/faq
            </a>{" "}
            und in der Datenschutzerklärung von Google:{" "}
            <a href="https://www.google.com/policies/privacy/">
              https://www.google.com/policies/privacy/
            </a>
            .
          </p>
        </div>
        <div className="absatz">
          <p>Drittlandtransfer:</p>
          <p>
            Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US
            Privacy Shield unterworfen{" "}
            <a href="https://www.privacyshield.gov/EU-US-Framework">
              https://www.privacyshield.gov/EU-US-Framework
            </a>
            .
          </p>
        </div>
        <div className="absatz">
          <p>Bereitstellung vorgeschrieben oder erforderlich:</p>
          <p>
            Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich,
            noch vertraglich vorgeschrieben. Allerdings kann ohne die korrekte
            Darstellung der Inhalte von Standardschriften nicht ermöglicht
            werden.
          </p>
        </div>
        <div className="absatz">
          <p>Widerruf der Einwilligung:</p>
          <p>
            Zur Darstellung der Inhalte wird regelmäßig die Programmiersprache
            JavaScript verwendet. Sie können der Datenverarbeitung daher
            widersprechen, indem Sie die Ausführung von JavaScript in Ihrem
            Browser deaktivieren oder einen Einbindung JavaScript-Blocker
            installieren. Bitte beachten Sie, dass es hierdurch zu
            Funktionseinschränkungen auf der Website kommen kann.
          </p>
        </div>
        <h2 className="imp_headline">Verwendung von Google Maps</h2>
        <div className="absatz">
          <p>Art und Zweck der Verarbeitung:</p>
          <p>
            Auf dieser Webseite nutzen wir das Angebot von Google Maps. Google
            Maps wird von Google LLC, 1600 Amphitheatre Parkway, Mountain View,
            CA 94043, USA (nachfolgend „Google“) betrieben. Dadurch können wir
            Ihnen interaktive Karten direkt in der Webseite anzeigen und
            ermöglichen Ihnen die komfortable Nutzung der Karten-Funktion.
          </p>
        </div>
        <div className="absatz">
          <p>
            Nähere Informationen über die Datenverarbeitung durch Google können
            Sie den Google-Datenschutzhinweisen entnehmen. Dort können Sie im
            Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen
            verändern.
          </p>
        </div>
        <div className="absatz">
          <p>
            Ausführliche Anleitungen zur Verwaltung der eigenen Daten im
            Zusammenhang mit Google-Produkten finden Sie hier.
          </p>
        </div>
        <div className="absatz">
          <p>Rechtsgrundlage:</p>
          <p>
            Rechtsgrundlage für die Einbindung von Google Maps und dem damit
            verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6
            Abs. 1 lit. a DSGVO).
          </p>
        </div>
        <div className="absatz">
          <p>Empfänger:</p>
          <p>
            Durch den Besuch der Webseite erhält Google Informationen, dass Sie
            die entsprechende Unterseite unserer Webseite aufgerufen haben. Dies
            erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt,
            über das Sie eingeloggt sind, oder ob keine Nutzerkonto besteht.
            Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem
            Konto zugeordnet.
          </p>
        </div>
        <div className="absatz">
          <p>
            Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht wünschen,
            müssen Sie sich vor Aktivierung des Buttons bei Google ausloggen.
            Google speichert Ihre Daten als Nutzungsprofile und nutzt sie für
            Zwecke der Werbung, Marktforschung und/oder bedarfsgerechter
            Gestaltung seiner Webseite. Eine solche Auswertung erfolgt
            insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung
            bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks
            über Ihre Aktivitäten auf unserer Webseite zu informieren. Ihnen
            steht ein Widerspruchsrecht zu gegen die Bildung dieser
            Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten
            müssen.
          </p>
        </div>
        <div className="absatz">
          <p>Speicherdauer:</p>
          <p>
            Wir erheben keine personenbezogenen Daten, durch die Einbindung von
            Google Maps.
          </p>
        </div>
        <div className="absatz">
          <p>Drittlandtransfer:</p>
          <p>
            Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US
            Privacy Shield unterworfen{" "}
            <a href="https://www.privacyshield.gov/EU-US-Framework">
              https://www.privacyshield.gov/EU-US-Framework
            </a>
            .
          </p>
        </div>
        <div className="absatz">
          <p>Widerruf der Einwilligung:</p>
          <p>
            Wenn Sie nicht möchten, dass Google über unseren Internetauftritt
            Daten über Sie erhebt, verarbeitet oder nutzt, können Sie in Ihrem
            Browsereinstellungen JavaScript deaktivieren. In diesem Fall können
            Sie unsere Webseite jedoch nicht oder nur eingeschränkt nutzen.
          </p>
        </div>
        <div className="absatz">
          <p>Bereitstellung vorgeschrieben oder erforderlich:</p>
          <p>
            Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
            allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff
            unterbinden, kann es hierdurch zu Funktionseinschränkungen auf der
            Website kommen.
          </p>
        </div>
        <div className="absatz">
          <p>SSL-Verschlüsselung</p>
          <p>
            Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
            verwenden wir dem aktuellen Stand der Technik entsprechende
            Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
          </p>
        </div>
        <div className="absatz">
          <p>Änderung unserer Datenschutzbestimmungen</p>
          <p>
            Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit
            sie stets den aktuellen rechtlichen Anforderungen entspricht oder um
            Änderungen unserer Leistungen in der Datenschutzerklärung
            umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren
            erneuten Besuch gilt dann die neue Datenschutzerklärung.
          </p>
        </div>
        <div className="absatz">
          <p>Fragen an den Datenschutzbeauftragten</p>
          <p>
            Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
            E-Mail oder wenden Sie sich direkt an die für den Datenschutz
            verantwortliche Person in unserer Organisation:
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Datenschutz;
